import * as React from "react"
import { graphql } from 'gatsby';

import Layout from "../../components/template/Layout"
import { PageProp, lang as Lang  } from '../../entities/pagepart'
import { CurrentView  } from '../../entities/templates'
import PagePartRenderer from './PagePartRenderer';
import useSetPagePartGlobal from '../../components/hooks/useSetPagePartGlobal'

const subNavtitle = {
  de: 'Über uns', en: 'About us'
}
const IndexPage: React.FC<PageProp> = ({ data, pageContext }) => {
  const pageParts = data?.pageData?.pageParts;

  useSetPagePartGlobal({ pageContext, locale: pageContext?.locale,
     currentView: CurrentView.pagePartView, 
     subNavigationItem: data?.pageData?.subnavigation?.items,
     subNavigationTitle: subNavtitle
  })

  return (
    <Layout 
      seoDescription={pageContext?.metadata?.page_description?.[pageContext?.locale as Lang]} 
      seoTitle={pageContext?.metadata?.page_title?.[pageContext?.locale as Lang]} 
      hideHero={true} 
      hideSubMenu={true}
    >
        {pageParts && pageParts.map((part, i) => <PagePartRenderer part={ part } key={i} id={i} 
        showSubMenu={!!data?.pageData?.subnavigation} />)}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query Page($id: String!) {
    pageData: sanityPage(_id: {eq: $id}) {
      __typename
      breadcrumbEnabled
      breadcrumbTitle {
        de
       
      }
      
      subnavigation {
        title
        items {
          name {
            _type
            de
           
          }
          link {
            ... on SanityCountry {
              typename: __typename
              id
              slug {
                de {
                  current
                }

              }
            }
            ... on SanityPage {
              typename: __typename
              id
              slug {
                de {
                  current
                }

              }
            }
          }
        }
      }

      pageParts {
        ... on SanityHero {
          _key
          _type
          part_style: partStyle
          navanchor
          hero: _rawImage(resolveReferences: {maxDepth: 10})
          body: _rawBody(resolveReferences: {maxDepth: 10})
          overlay_card: overlayCard {
            _key
            _type
            partStyle
            image: _rawImage(resolveReferences: {maxDepth: 10})
            body: _rawBody(resolveReferences: {maxDepth: 10})
            callToAction {
              de
             
            }
            cardStyle
            iconHint
          }
        }

        ... on SanitySimpleContentGrid {
          _key
          _type
          part_style: partStyle
          navanchor
          headerImage: _rawHeaderImage(resolveReferences: {maxDepth: 10})
          body: _rawBody(resolveReferences: {maxDepth: 10})
          contentBlocks {
            _key
            image: _rawImage(resolveReferences: {maxDepth: 10})
            body: _rawBody(resolveReferences: {maxDepth: 10})
          }
          button: _rawButton(resolveReferences: {maxDepth: 3})
        }
  
        ... on SanitySimpleContentBlock {
          _key
          _type
          part_style: partStyle
          navanchor
          image: _rawImage(resolveReferences: {maxDepth: 10})
          image_placement
          body: _rawBody(resolveReferences: {maxDepth: 10})
          button: _rawButton(resolveReferences: {maxDepth: 3})
        }

        ... on SanityCardWide {
          _key
          _type
          part_style: partStyle
          navanchor
          image: _rawImage(resolveReferences: {maxDepth: 10})
          body: _rawBody(resolveReferences: {maxDepth: 10})
          callToAction{
              de
             
          }
          cardStyle
          iconHint
          button: _rawButton(resolveReferences: {maxDepth: 3})
          introCopy:_rawIntroCopy
        }

        ... on SanityCardCollection {
          _key
          _type
          part_style: partStyle
          navanchor
          cardCollectionStyle 
          heading {
            de
           
          }
          intro: _rawIntro(resolveReferences: {maxDepth: 10})
          button: _rawButton(resolveReferences: {maxDepth: 2})
          cards {
            _id
            image: _rawImage(resolveReferences: {maxDepth: 10})
            body: _rawBody(resolveReferences: {maxDepth: 10})
            subheading {
              de
             
            }
            cardNoTravelBag
            title
            heading {
              de
             
            }
            cardLink: _rawCardLinks(resolveReferences: {maxDepth: 2})
            button {
              buttonText {
                de
               
              }
              buttonHref
              buttonLink {
                ... on SanityPage {
                  id
                  __typename
                  slug {
                    de {
                      current
                    }

                  }
                }
                ... on SanityCountry {
                  id
                  __typename
                  slug {
                    de {
                      current
                    }

                  }
                }
              }
            }
          }
        }

        ... on SanityFormPart {
          _key
          _type
          part_style: partStyle
          navanchor
          simpleForm {
            _id
            _key
            title
            formId
            image: _rawImage(resolveReferences: {maxDepth: 10})
            body: _rawBody(resolveReferences: {maxDepth: 10})
            additionalContent: _rawAdditionalContent(resolveReferences: {maxDepth: 10})
          }
        }

        ... on SanityPictureGallery {
          _key
          _type
          pictures {
            alt {
              de
             
            }
            image: _rawImage(resolveReferences: {maxDepth: 1})
            _type
          }
        }

        ... on SanityCheckoutPart {
          _key
          _type
          navanchor
          part_style: partStyle
          part_type :partType
          title
          body: _rawBody
        }
      }
    }
  }
`